import http from '../../services/http'
import Env from './../../env'
import { parseProductsToEmbeds } from './messages'

const state = {
  isOpen: false,
  loading: false,
  authState: null,
  locationID: null,
  chatMode: 'default',
  hints: []
}
const mutations = {
  setIsOpen (state, isOpen) {
    state.isOpen = isOpen
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  setAuthState (state, authState) {
    state.authState = authState
  },
  setLocationId (state, locationId) {
    state.locationID = locationId
  },
  setChatMode (state, chatMode) {
    state.chatMode = chatMode
  },
  setHints (state, hints) {
    state.hints = hints
  }
}
const getters = {
  getIsOpen (state) {
    return state.isOpen
  },
  getLoading (state) {
    return state.loading
  },
  getAuthState (state) {
    return state.authState
  },
  getChatMode (state) {
    return state.chatMode
  },
  getHints (state) {
    return state.hints
  }
}
const actions = {
  fetchMe ({ commit, dispatch }) {
    if (!localStorage.getItem('accessToken')) {
      dispatch('closeChat')
      return
    }
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      http
        .get('/user/me/').then((r) => {
          dispatch('checkLatestSession')
          commit('setLoading', false)
          commit('setIsOpen', true)
          commit('setAuthState', 'loggedIn')
          resolve(r.data)
        })
        .catch((e) => {
          commit('setLoading', false)
          dispatch('closeChat')
          reject(e)
        })
    })
  },
  setHints ({ commit, dispatch }, hints) {
    commit('setHints', hints)
    setTimeout(() => {
      dispatch('calculateBodyHeight')
    }, 1)
  },
  setLocationId ({ commit }, locationId) {
    if (locationId) {
      localStorage.setItem('locationId', locationId)
    }
  },
  closeChat ({ commit }) {
    commit('messages/resetMessages', null, { root: true })
    commit('messages/setWsStatus', 'finished', { root: true })
    commit('auth/resetAuth', null, { root: true })
    commit('translations/resetTranslations', null, { root: true })
    commit('setAuthState', 'loggedOut')
  },
  checkLatestSession ({ commit, dispatch, state, rootGetters }) {
    const welcomeMessage = rootGetters['messages/prepareWelcomeMessage']
    if (state.chatMode === 'presenter') {
      dispatch('calculateBodyHeight')
      return
    }

    http.get(`${Env.CHAT_CORE}/conversations/latest`).then((r) => {
      const conversations = r.data.interactions.map(interaction => {
        const products = interaction.products ? parseProductsToEmbeds(interaction.products) : null
        delete interaction.products
        return {
          ...interaction,
          state: 'finished',
          embeds: products,
          embeds_status: products ? products.length : null
        }
      })

      // add welcome message at the beginning of the conversation
      if (!conversations.length) {
        conversations.unshift(welcomeMessage)
      }

      // add welcome message after latest conversation
      if (conversations.length) {
        conversations.push(welcomeMessage)
      }
      commit('messages/setWsContinue', true, { root: true })
      commit('messages/updateMessages', conversations, { root: true })
    }).catch(() => {
      commit('messages/setWsContinue', false, { root: true })
      commit('messages/updateMessages', [welcomeMessage], { root: true })
    }).finally(() => {
      commit('setLoading', false)
      dispatch('calculateBodyHeight')
    })
  },
  calculateBodyHeight ({ dispatch }) {
    const header = document.querySelector('.chatHeader')
    const footer = document.querySelector('.chatFooter')
    const body = document.querySelector('.chatBody')
    const hints = document.querySelector('.message-hints')

    if (!header || !footer || !body) {
      return
    }

    const hintsHeight = state.hints.length > 0 && hints ? hints.clientHeight : 0

    const offset = (header.clientHeight + footer.clientHeight + hintsHeight) + 12

    // Adjust the chat body's maximum height
    body.style.maxHeight = `${window.innerHeight - offset}px`
    body.style.bottom = `${footer.clientHeight + hintsHeight}px`
    body.style.top = `${header.clientHeight}px`

    setTimeout(() => {
      dispatch('calculateInsideHeight').then(() => dispatch('scrollToEnd'))
    }, 100)
  },
  calculateInsideHeight () {
    return new Promise((resolve) => {
      setTimeout(() => {
        const body = document.querySelector('.chatBody')
        const buyGet = document.querySelector('.buy-get')
        const chatInfo = document.querySelector('.chat-info')
        const inside = document.querySelector('.chatInside')
        inside.style.maxHeight = `${body.clientHeight - (buyGet ? buyGet.clientHeight : 0) - (chatInfo ? chatInfo.clientHeight : 0) - 20}px`
        resolve()
      }, 100)
    })
  },
  scrollToEnd () {
    const chatBody = document.querySelector('.chatInside')
    chatBody.scrollTop = chatBody.scrollHeight
  },
  switchChatMode ({ commit, dispatch, state, rootGetters }, mode) {
    commit('setChatMode', mode)
    commit('setHints', [])
    // dispatch('messages/closeWebSocketConnection', null, { root: true })
    commit('messages/resetMessages', null, { root: true })
    dispatch('messages/sendMessage', rootGetters['chat/getChatMode'] === 'presenter' ? rootGetters['messages/preparePresenterWelcomeMessage'] : null, { root: true })
    dispatch('checkLatestSession')
    dispatch('messages/initWebSocket', null, { root: true })
    if (mode === 'default') {
      commit('messages/setWsStatus', 'finished', { root: true })
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
