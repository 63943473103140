import i18n from '../../i18n/lang'

const state = {
  interfaceLang: i18n.locale || 'en',
  chatLang: localStorage.getItem('aica_c_lang') || null
}

const mutations = {
  setInterfaceLang (state, lang) {
    state.interfaceLang = lang
  },
  setChatLang (state, lang) {
    state.chatLang = lang
  },
  resetTranslations (state) {
    state.chatLang = null
    localStorage.removeItem('aica_c_lang')
  }
}

const getters = {
  getInterfaceLang (state) {
    return state.interfaceLang
  },
  getChatLang (state) {
    return state.chatLang
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
}
