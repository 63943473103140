<template>
    <div class="message-embed">
        <div class="picture">
            <img :src="embed.picture" :alt="embed.title + ' Picture'" />
            <span class="price" v-if="embed.price">
                {{ price }}
            </span>
        </div>
        <div class="content">
            <h4>{{ computeTitle }}</h4>
            <p>{{ embed.description }}}</p>
            <span></span>
            <button @click="$emit('open-modal')">
                {{ $t('chat.more_information') }}
            </button>
        </div>
    </div>
</template>

<script>
import Env from '../../env'

export default {
  name: 'MessageEmbed',
  props: {
    embed: {
      type: Object,
      required: true
    }
  },
  computed: {
    price () {
      return new Intl.NumberFormat(undefined, { style: 'currency', currency: 'PLN' }).format(this.embed.price)
    },
    computeTitle () {
      let title = ''

      if (Env.SHOW_EMBED_PRODUCT_BRAND) {
        title += this.embed.shop_name
      }

      if (Env.SHOW_EMBED_PRODUCT_BRAND && Env.SHOW_EMBED_PRODUCT_NAME) {
        title += ' | '
      }

      if (Env.SHOW_EMBED_PRODUCT_NAME) {
        title += this.embed.title
      }

      return title
    }
  }
}
</script>

<style scoped lang="scss">
  .message-embed {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-shrink: 0;
    padding: 0 0 13px 0;
    border-radius: 6px;
    border: 1px solid $message-embed-border;
    background: #f9f9f9;
    overflow: hidden;
    max-width: 200px;
    user-select: none;

    .picture {
        height: 125px;
        min-height: 125px;
        max-height: 125px;
        position: relative;
        text-align: center;
        display: flex;
        justify-content: center;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .price {
            position: absolute;
            left: 10px;
            bottom: 8px;
            display: flex;
            padding: 3px 10px;
            justify-content: center;
            align-items: center;
            border-radius: 15px;
            background: $message-embed-price-bg;
            color: #FFF6F9;
            font-family: Roboto;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px; /* 140% */
          }
    }

    .content {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        padding: 0 10px;
        height: 100%;
        align-items: flex-start;

        h4 {
            color: $message-embed-text;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        p {
            color: #555;
            font-family: 'Roboto', sans-serif;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            white-space: normal;
        }

        button {
            display: flex;
            padding: 5px 20px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 15px;
            border: 1px solid $message-embed-border;
            background: $message-embed-button-bg;
            cursor: pointer;
            width: 100%;
            color: $message-embed-button-text;
            font-family: 'Roboto', sans-serif;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            margin-top: auto;

            &:hover {
                background: $message-embed-button-bg-hover;
            }
        }
    }
  }
  </style>
