<template>
  <div class="chatHeader">
    <div class="chatHeader__title">
      <picture>
        <img :src="require('@/assets/img/avatars/' + avatar)" alt="Avatar"/>
      </picture>
      <div class="chatHeader__title__block">
        <span class="chatHeader__title--name">
          {{ $t('chat.title') }}
        </span>
        <span class="chatHeader__title--subtitle">
          <template v-if="!showPoweredByAica">
            {{ $t('chat.subtitle') }}
          </template>
          <template v-else>
            {{ $t('chat.powered_by') }} <a v-if="!fromWebView" href="https://aica.si/" target="_blank">AICA</a> <span v-if="fromWebView">AICA</span>
          </template>
        </span>
      </div>
    </div>
    <div class="chatHeader__settings">
      <LanguageSwitch global/>
    </div>
    <div class="chatHeader__actions" :style="{ 'width': fromWebView ? '15vw' : 'auto' }">
      <span @click="$emit('close')" class="close" :style="{ 'visibility': fromWebView ? 'hidden' : 'visible' }">
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 24 24">
          <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"></path>
        </svg>
        <small>{{ $t('chat.logout') }}</small>
      </span>
    </div>
  </div>
</template>

<script>
import LanguageSwitch from '../elements/LanguageSwitch'

import Env from './../../env'

export default {
  name: 'Header',
  components: { LanguageSwitch },
  computed: {
    avatar () {
      return Env.HEADER_AVATAR || 'avatar.png'
    },
    showPoweredByAica () {
      return Env.HEADER_SHOW_POWERED_BY_AICA
    },
    fromWebView () {
      const urlParams = new URLSearchParams(window.location.search)
      return urlParams.has('fromWebView') && urlParams.get('fromWebView') === 'true'
    }
  }
}
</script>

<style scoped lang="scss">
.chatHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background: #FFFFFF;
  border-bottom: 1px solid #F1F1F1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  &__title {
    display: flex;
    align-items: center;
    padding: 0;
    flex: none;
    order: 0;
    flex-grow: 0;
    gap: 10px;

    picture {
      width: 38px;
      height: 38px;

      img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
      }
    }

    &__block {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &--name {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 15px;

      color: #2C2C2E;
    }

    &--subtitle {
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 11px;
      color: #999;

      a {
        color: #999;
        text-decoration: none;
      }
    }
  }

  &__settings {
    margin-left: auto;
    margin-right: 30px;
    @media (max-width: 480px) {
      margin-right: 10px;
      padding-right: 10px;
    }
  }

  &__actions {
    span {
      background: none;
      border: none;
      cursor: pointer;
      color: #aaa;
      font-size: 13px;
      display: inline-flex;
      align-items: center;
      transition: all .2s;

      &.close {
        display: flex;
        flex-direction: column;
      }

      small {
        font-size: 10px;
        margin-top: 2px;
      }

      svg {
        max-width: 16px;
        height: auto;
        margin-left: 0;
        transition: all .2s;
        path {
          transition: all .2s;
          fill: #aaa;
        }
      }

      &:hover {
        color: #131313;
        svg {
          transform: rotate(90deg);
          path {
            fill: #131313;
          }
        }
      }
    }
  }
}
</style>
