<template>
    <transition name="fade">
      <div class="message-embed-modal" :class="data.class ? data.class : null">
        <div class="wrapper">
          <div class="header-modal">
            <div class="picture">
              <img :src="data.picture" :alt="data.title + ' Picture'" />
              <span class="price" v-if="data.price">
                {{ price }}
              </span>
            </div>

            <button v-if="data.closable === undefined || data.closable" type="button" class="close" @click="$emit('close')">
              {{ $t('presenter.close') }}
            </button>
          </div>

          <div class="content-modal">
            <h3>{{ computeTitle }}</h3>
            <p class="text-intro">{{ data.description }}</p>
            <div class="options" v-if="data.options && data.options.length">
              <button :class="'option--' + option.type" v-for="(option, i) in data.options" :key="'option-'+ i" @click="$emit('select-option', option.id)">
                <span class="icon" v-if="option.icon" v-html="option.icon"></span>
                {{ $t(option.title) }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </template>

<script>
import Env from '../../env'

export default {
  name: 'MessageEmbedModal',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    price () {
      return new Intl.NumberFormat(undefined, { style: 'currency', currency: 'PLN' }).format(this.data.price)
    },
    computeTitle () {
      let title = ''

      if (Env.SHOW_EMBED_PRODUCT_BRAND) {
        title += this.data.shop_name
      }

      if (Env.SHOW_EMBED_PRODUCT_BRAND && Env.SHOW_EMBED_PRODUCT_NAME) {
        title += ' | '
      }

      if (Env.SHOW_EMBED_PRODUCT_NAME) {
        title += this.data.title
      }

      return title
    }
  }
}
</script>

<style scoped lang="scss">
  .message-embed-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .25);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;

    &.detected-present {
      .content-modal {
        h3 {
          color: $message-primary-button-bg !important;
        }
      }
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
      background: #fff;
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);
      border-radius: 6px;
      max-width: 450px;
      margin: 0 10px;
      width: 100%;
      position: relative;
      overflow: auto;
      max-height: 90vh;

      .header-modal {
        .picture {
          height: 225px;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .price {
            position: absolute;
            left: 10px;
            bottom: 8px;
            display: flex;
            padding: 3px 10px;
            justify-content: center;
            align-items: center;
            border-radius: 15px;
            background: $message-embed-price-bg;
            color: #FFF6F9;
            font-family: Roboto;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
          }
        }

        .close {
          position: absolute;
          top: 15px;
          right: 15px;
          cursor: pointer;
          padding: 5px 20px;
          border-radius: 15px;
          display: flex;
          align-items: center;
          gap: 10px;

          border-radius: 15px;
          border-radius: 15px;
          border: 1px solid $message-embed-button-text;
          background: $message-embed-button-bg;

          color: $message-embed-button-text;
          font-family: Roboto;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;

          &:hover {
            background: $message-embed-button-bg-hover;
          }
        }
      }

      .content-modal {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding: 0px 20px 20px 20px;

        @media (max-width: 996px) {
          padding: 15px;
        }

        h3 {
            color: $message-embed-text;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
        }

        p {
            color: #555;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            &::v-deep {
                a {
                color: #333;
                text-decoration: underline;
                &:hover {
                    color: #131313;
                    text-decoration: none;
                }
                }
            }
        }

        .options {
          display: flex;
          flex-direction: row;
          gap: 17px;
          margin-top: 10px;
          button {
            display: flex;
            flex-direction: row;
            padding: 4px 16px;
            align-items: center;
            justify-content: center;
            gap: 10px;
            cursor: pointer;
            border: 0;
            border-radius: 15px;

            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px; /* 166.667% */

            &.option--primary {
              background: $message-primary-button-bg;
              color: $message-primary-button-text;

              &:hover {
                background: $message-primary-button-bg-hover;
              }
            }

            &.option--secondary {
              background: $message-secondary-button-bg;
              color: $message-secondary-button-text;

              &:hover {
                background: $message-secondary-button-bg-hover;
              }
            }

            .icon {
              display: flex;
              align-items: center;
            }

          }
        }
      }
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
  }
  </style>
