import Vue from 'vue'
import http from '../../services/http'
import i18n from '../../i18n/lang'
import Env from './../../env'

const state = {
  status: null,
  prizes: null
}

const mutations = {
  setStatus (state, status) {
    state.status = status
  },
  setPrizes (state, establishments) {
    state.prizes = establishments
  }
}

const getters = {
  getStatus (state) {
    return state.status
  },
  getPrizes (state) {
    return state.prizes
  }
}

const actions = {
  getUserStatus ({ commit, dispatch }) {
    return new Promise((resolve) => {
      commit('chat/setLoading', true, { root: true })
      http
        .get(`${Env.BUY_GET_URL}/status`)
        .then((r) => {
          const data = r.data
          commit('setStatus', data)

          if (!data.is_participant) {
            return
          }

          dispatch('getPrizes', null).then(prizes => commit('setPrizes', prizes.prizes))
          dispatch('chat/calculateBodyHeight', null, { root: true })

          resolve(r.data)
        }).finally(() => commit('chat/setLoading', false, { root: true }))
    })
  },
  getPrizes () {
    return new Promise((resolve) => {
      http
        .get(`${Env.BUY_GET_URL}/prizes`)
        .then((r) => {
          resolve(r.data)
        })
    })
  },
  getPrize ({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      commit('chat/setLoading', true, { root: true })
      http
        .post(`${Env.BUY_GET_URL}/receive-prize`, { prize_id: payload.id })
        .then((r) => {
          dispatch('getUserStatus', { received: true })
          Vue.$toast.success(`${i18n.t('buy-get.prize_received')}: ${payload.title[i18n.locale.toUpperCase()]}`)
          resolve(r.data)
        }).catch((e) => {
          Vue.$toast.error(i18n.t('buy-get.cannot_to_claim_today'))
          commit('chat/setLoading', false, { root: true })
          reject(payload)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
