import Vue from 'vue'
import Vuex from 'vuex'

import chat from './modules/chat'
import auth from './modules/auth'
import messages from './modules/messages'
import buyGet from './modules/buy-get'
import translations from './modules/translations'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    chat,
    auth,
    messages,
    buyGet,
    translations
  },
  strict: true
})
