import axios from 'axios'
import Env from './../env'

const http = axios.create({
  baseURL: Env.SSO_URL
})

http.interceptors.request.use(
  config => {
    const token = localStorage.getItem('accessToken')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// http.interceptors.response.use(
//   (res) => {
//     return Promise.resolve(res.data)
//   },
//   (err) => {
//     return Promise.reject(err)
//   }
// )

export default http
