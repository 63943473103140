<template>
  <div class="message-hints" :style="{ visibility: !hints.length ? 'hidden' : 'visible' }" :class="{ webview : fromWebView }">
    <button v-for="(hint, index) in hints" :key="hint + index" class="message-hint" @click="sendHint(hint)">
      {{ hint }}
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'MessageHints',
  computed: {
    ...mapGetters({
      hints: 'chat/getHints'
    }),
    fromWebView () {
      const urlParams = new URLSearchParams(window.location.search)
      return urlParams.has('fromWebView') && urlParams.get('fromWebView') === 'true'
    }
  },
  methods: {
    ...mapActions({
      sendHint: 'messages/sendHint'
    })
  }
}
</script>

<style lang="scss" scoped>
.message-hints {
  display: flex;
  justify-content: flex-start;
  padding: 8px 12px;
  background-color: $message-hints-bg;
  position: fixed;
  bottom: 77px;
  width: 100%;
  gap: 10px;
  overflow: auto;
  white-space: nowrap;
  box-sizing: border-box;
  &.webview {
    bottom: 15px;
  }
  &::before {
    content: '';
    position: fixed;
    left: 0;
    width: 25px;
    height: 46px;
    margin-top: -8px;
    background: $message-hints-gradient-before;
  }
  &::after {
    content: '';
    position: fixed;
    right: 0;
    width: 25px;
    height: 46px;
    margin-top: -8px;
    background: $message-hints-gradient-after;
  }
  .message-hint {
    height: 30px;
    padding: 2px 12px;
    background-color: $message-hints-button-bg;
    border: 1px solid $message-hints-button-border;
    border-radius: 20px;
    color: $message-hints-button-text;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    transition: background-color 300ms ease-out;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: $message-hints-button-bg-hover;
    }
  }
}

</style>
