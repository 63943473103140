<template>
  <div>
    <template v-if="embeds && embeds.length">
      <transition name="fade-in">
          <div class="message-embeds">
            <button v-if="showLeftScroll" @click.stop="scrollLeft" class="scroll-button scroll-button--left">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path d="M3.56886 6.53595L8.88296 1.22188C9.13925 0.965587 9.55477 0.965587 9.81103 1.22188L10.4308 1.84168C10.6867 2.09754 10.6872 2.5122 10.4319 2.76866L6.22042 7L10.4319 11.2313C10.6872 11.4878 10.6867 11.9024 10.4308 12.1583L9.81103 12.7781C9.55474 13.0344 9.13922 13.0344 8.88296 12.7781L3.56889 7.46402C3.3126 7.20776 3.3126 6.79224 3.56886 6.53595Z" fill="currentColor"/>
              </svg>
            </button>
            <button v-if="showRightScroll" @click.stop="scrollRight" class="scroll-button scroll-button--right">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path d="M10.4311 7.46402L5.11704 12.7781C4.86075 13.0344 4.44523 13.0344 4.18897 12.7781L3.56917 12.1583C3.31331 11.9024 3.31282 11.4878 3.56807 11.2313L7.77958 6.99997L3.56807 2.76866C3.31282 2.51221 3.31331 2.09754 3.56917 1.84168L4.18897 1.22188C4.44526 0.96559 4.86078 0.96559 5.11704 1.22188L10.4311 6.53595C10.6874 6.79221 10.6874 7.20773 10.4311 7.46402Z" fill="currentColor"/>
              </svg>
            </button>
            <div ref="embedsScrollArea" @scroll="onEmbedsAreaScroll" class="wrapper">
              <message-embed v-for="(embed, i) in embeds" :key="'embed-'+ i" :embed="embed" @open-modal="openModal(embed)"></message-embed>
            </div>
          </div>
      </transition>
      <slot name="products_found"/>
      <message-embed-modal v-if="modal.open" :data="modal.data" @close="closeModal"></message-embed-modal>
    </template>
    <slot name="products_not_found" v-if="embedsStatus === 0"/>
  </div>
</template>

<script>
import MessageEmbed from './MessageEmbed.vue'
import MessageEmbedModal from './MessageEmbedModal'

export default {
  name: 'MessageEmbeds',
  components: { MessageEmbed, MessageEmbedModal },
  props: {
    embeds: {
      type: Array,
      default: null
    },
    embedsStatus: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      showLeftScroll: true,
      showRightScroll: true,
      modal: {
        open: false,
        data: null
      }
    }
  },
  mounted () {
    this.initScrollButtonsVisibility()
    window.addEventListener('resize', this.initScrollButtonsVisibility)
  },
  destroyed () {
    window.removeEventListener('resize', this.initScrollButtonsVisibility)
  },
  methods: {
    onEmbedsAreaScroll () {
      if (!this.$refs.embedsScrollArea) {
        return
      }

      this.showLeftScroll = this.$refs.embedsScrollArea.scrollLeft !== 0
      this.showRightScroll = this.$refs.embedsScrollArea.scrollLeft !== (this.$refs.embedsScrollArea.scrollWidth - this.$refs.embedsScrollArea.clientWidth)
    },
    initScrollButtonsVisibility () {
      if (!this.$refs.embedsScrollArea) {
        return
      }

      this.showLeftScroll = this.$refs.embedsScrollArea.scrollLeft !== 0
      this.showRightScroll = this.$refs.embedsScrollArea.scrollLeft !== (this.$refs.embedsScrollArea.scrollWidth - this.$refs.embedsScrollArea.clientWidth)
    },
    scrollLeft () {
      if (!this.$refs.embedsScrollArea) {
        return
      }

      const left = this.$refs.embedsScrollArea.scrollLeft - 222

      this.$refs.embedsScrollArea.scrollTo({
        left: left < 0 ? 0 : left,
        behavior: 'smooth'
      })
    },
    scrollRight () {
      if (!this.$refs.embedsScrollArea) {
        return
      }

      const left = this.$refs.embedsScrollArea.scrollLeft + 222
      const endScroll = this.$refs.embedsScrollArea.scrollWidth - this.$refs.embedsScrollArea.clientWidth
      this.$refs.embedsScrollArea.scrollTo({
        left: left < endScroll ? left : endScroll,
        behavior: 'smooth'
      })
    },
    openModal (data) {
      this.modal.open = true
      this.modal.data = data
    },
    closeModal () {
      this.modal.open = false
      this.modal.data = null
    }
  }
}
</script>

<style scoped lang="scss">
  .message-embeds {
    margin: 0 0 15px 0;
    border-radius: 6px;
    background: #F2F2F7;
    position: relative;
    width: auto;
    max-width: 908px;
    user-select: none;
    z-index: 0;

    .scroll-button {
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      border: 1px solid $message-embeds-scroll-button-border;
      background: $message-embeds-scroll-button-bg;
      user-select: none;

      width: 36px;
      height: 36px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1000;
      cursor: pointer;
      svg {
        color: $message-embeds-scroll-button-text;
      }

      &--left {
        left: -12px;
      }

      &--right {
        right: -12px;
      }
    }

    .wrapper {
      display: flex;
      flex-direction: row;
      gap: 20px;
      padding: 20px;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari and Opera */
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }
    }
  }
  </style>
