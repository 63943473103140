<template>
  <div class="chatFooter" :style="{ 'display': fromWebView ? 'none' : null }">
    <MessageNotice
      v-if="isMessageTooLong"
      type='message-input-error'
      :text="$t('chat.message_too_long')"
    />
    <div class="inner">
      <input
        :class="{ 'input-message-too-long': isMessageTooLong}"
        type="text"
        :placeholder="$t('chat.type_message')"
        name="chatMessage"
        v-model="message"
        ref="chatMessage"
        @keyup.enter="sendMessage"
        :disabled="wsStatus === 'limit_exceeded'"
        autocomplete="off"
        :maxlength="maxMessageCharacters"
      />
      <button type="button" @click="sendMessage" :disabled="!allowSend || isMessageTooLong">{{ $t('chat.send') }}</button>
    </div>
    <div class="info">{{ $t('chat.disclaimer') }}</div>
  </div>
</template>

<script>

import MessageNotice from '../elements/MessageNotice.vue'

import { mapGetters } from 'vuex'
const presenterStatuses = ['insert_price', 'insert_price_repeat', 'insert_for_whom', 'insert_for_whom_repeat', 'insert_category', 'insert_category_repeat']

export default {
  name: 'Footer',
  components: { MessageNotice },
  data () {
    return {
      message: null,
      maxMessageCharacters: 255,
      allowedStatuses: ['finished', 'validation_failed', 'language_not_allowed', 'moderation_error', ...presenterStatuses]
    }
  },
  computed: {
    ...mapGetters({
      wsStatus: 'messages/getWsStatus'
    }),
    allowSend () {
      return this.allowedStatuses.includes(this.wsStatus) && this.wsStatus !== 'limit_exceeded' && this.message
    },
    isMessageTooLong () {
      if (!this.message) {
        return false
      }
      return this.message.length > this.maxMessageCharacters - 1
    },
    fromWebView () {
      const urlParams = new URLSearchParams(window.location.search)
      return urlParams.has('fromWebView') && urlParams.get('fromWebView') === 'true'
    }
  },
  watch: {
    wsStatus (n) {
      if (this.allowedStatuses.includes(n) && !this.fromWebView) {
        this.$refs.chatMessage.focus()
      }
      window.AllowSendMessage = this.allowedStatuses.includes(this.wsStatus) && this.wsStatus !== 'limit_exceeded'
    }
  },
  beforeCreate () {
    window.AllowSendMessage = false
  },
  mounted () {
    if (!this.fromWebView) {
      this.$refs.chatMessage.focus()
    }

    window.AllowSendMessage = this.allowedStatuses.includes(this.wsStatus) && this.wsStatus !== 'limit_exceeded'
    document.addEventListener('webview-message', (event) => {
      this.$emit('send', event.detail.message)
      this.message = ''
    })
  },
  methods: {
    sendMessage () {
      if (!this.allowSend) {
        return
      }
      this.$emit('send', this.message)
      this.message = ''
    }
  }
}
</script>

<style scoped lang="scss">
.chatFooter {
  background: #FFFFFF;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  border-top: 1px solid #F1F1F1;
  .inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    gap: 10px;
    input[type=text] {
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #555;
      border: none;
      flex: 1;
      margin: 0;
      padding: 0;
      font-family: 'Roboto', sans-serif;
      font-size: 13px;

      &.input-message-too-long {
        color: #d05759;
      }

      &:focus, &:active, &:disabled {
        border: 0;
        outline: 0;
        background-color: #fff !important;
      }
      &:-webkit-autofill:focus, &:-webkit-autofill {
        -webkit-box-shadow:0 0 0 50px white inset;
        -webkit-text-fill-color: #333;
      }
    }

    button {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 7px 20px;
      gap: 10px;
      color: $message-footer-send-button-text;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: 0.5px;
      background-color: $message-footer-send-button-bg;
      border-radius: 15px;
      border: none;
      font-weight: 500;
      cursor: pointer;
      margin: 0;
      font-family: 'Roboto', sans-serif;
      -webkit-transition: all 1s ease-out;
      -moz-transition: all 1s ease-out;
      -o-transition: all 1s ease-out;
      transition: all 1s ease-out;
      &:not(:disabled):hover {
        background-color: $message-footer-send-button-bg-hover;
        color: $message-footer-send-button-text-hover;
      }
      &:disabled {
        opacity: .7;
        cursor: default;
      }
    }
  }
  .info {
    flex: 0 0 100%;
    background: #f9f9f9;
    font-size: 9px;
    padding: 6px 15px;
    color: #999;
    text-align: center;
    border-top: 1px solid #F1F1F1;
  }
}
</style>
