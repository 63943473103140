<template>
  <div class="prizes">
    <div class="title">
      <h4>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.26312 5.64595C5.30469 4.9722 5.64156 4.3072 6.22563 3.90032C6.11188 3.66407 5.87781 3.50001 5.6 3.50001H4.95687L5.62844 3.01876C5.78594 2.9072 5.82312 2.68845 5.70937 2.53095L5.50594 2.24657C5.39437 2.08907 5.17562 2.05189 5.01812 2.16564L4.31812 2.66657L4.56969 1.9972C4.6375 1.81564 4.54562 1.61439 4.36406 1.54657L4.03594 1.42407C3.85437 1.35626 3.65312 1.44814 3.58531 1.6297L3.15 2.78907L2.71469 1.62751C2.64687 1.44595 2.44563 1.35407 2.26406 1.42189L1.93594 1.54439C1.75437 1.6122 1.6625 1.81564 1.7325 1.99501L1.98406 2.66439L1.28187 2.16564C1.12437 2.05407 0.905625 2.08907 0.794062 2.24657L0.590625 2.53095C0.479062 2.68845 0.514063 2.9072 0.671562 3.01876L1.34312 3.50001H0.7C0.312812 3.50001 0 3.81282 0 4.20001V11.9C0 12.2872 0.312812 12.6 0.7 12.6H4.39469C4.27437 12.3922 4.2 12.1559 4.2 11.9V7.00001C4.2 6.34595 4.655 5.79689 5.26312 5.64595ZM4.9 11.9C4.9 12.2872 5.21281 12.6 5.6 12.6H9.1V9.80001H4.9V11.9ZM9.8 12.6H13.3C13.6872 12.6 14 12.2872 14 11.9V9.80001H9.8V12.6ZM13.3 6.30001H12.8537C12.9106 6.13376 12.95 5.96095 12.95 5.77939C12.95 5.00282 12.3594 4.20001 11.3728 4.20001C10.3206 4.20001 9.7125 5.24345 9.45 5.8472C9.18531 5.24345 8.57719 4.20001 7.52719 4.20001C6.54063 4.20001 5.95 5.00282 5.95 5.77939C5.95 5.96095 5.98719 6.13376 6.04625 6.30001H5.6C5.21281 6.30001 4.9 6.61282 4.9 7.00001V9.10001H9.1V6.30001H9.43469L9.45 6.29564L9.46531 6.30001H9.8V9.10001H14V7.00001C14 6.61282 13.6872 6.30001 13.3 6.30001ZM7.35 6.30001C7.29094 6.26939 7.23844 6.23439 7.1925 6.19501C7.03281 6.05501 7 5.89314 7 5.77939C7 5.5672 7.14 5.25001 7.52719 5.25001C7.93625 5.25001 8.30594 5.84939 8.50062 6.30001H7.35ZM11.7075 6.19501C11.6616 6.23439 11.6091 6.26939 11.55 6.30001H10.3994C10.5919 5.85595 10.9637 5.25001 11.3728 5.25001C11.76 5.25001 11.9 5.5672 11.9 5.77939C11.9 5.89314 11.8672 6.05501 11.7075 6.19501Z"
            fill="#0277BD"/>
        </svg>
        {{ $t('buy-get.my_prizes') }}
      </h4>

      <div class="filters">
        <div class="filter" v-if="getPrizes.length">
          <input type="radio" value="new" v-model="filter" id="new" />
          <label for="new">{{ $t('buy-get.to_receive') }} ({{ this.getPrizes.length }})</label>
        </div>
        <div class="filter" v-if="getUserPrizes.length">
          <input type="radio" value="old" v-model="filter" id="old" />
          <label for="old">{{ $t('buy-get.received') }} ({{ getUserPrizes.length }})</label>
        </div>
      </div>

      <span @click="$emit('close')" class="close">
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 24 24">
          <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"></path>
        </svg>
        {{ $t('buy-get.hide') }}
      </span>
    </div>

    <div class="prizes-list">
      <VueSlickCarousel v-bind="carouselSettings" v-if="filter === 'new'">
        <Prize v-for="(prize, i) in getPrizes" :key="'A'+ i" :prize="{ prize: prize }" :status="status" @openDescription="openDescriptionModal"
               @openConfirmation="openConfirmationModal"/>
      </VueSlickCarousel>
    </div>

    <div class="prizes-list">
      <VueSlickCarousel v-bind="carouselSettings" v-if="filter === 'old'">
        <Prize v-for="(prize, i) in getUserPrizes" :key="'B'+ i" :prize="prize"
               @openDescription="openDescriptionModal" @openConfirmation="openConfirmationModal"/>
      </VueSlickCarousel>
    </div>

    <ModalDescription v-if="modalDescription.open" :data="modalDescription.data" @close="closeDescriptionModal" @collect="openConfirmationModal" />
    <ModalConfirmation v-if="modalConfirmation.open" :data="modalConfirmation.data" @close="closeConfirmationModal" @collect="collectPrize" />
    <PrizeReceivedModal v-if="modalReceived.open" :data="modalReceived.data" @close="closePrizeReceivedModal" />
  </div>
</template>

<script>
import Prize from './Prize'
import ModalDescription from './ModalDescription'
import ModalConfirmation from './ModalConfirmation'
import PrizeReceivedModal from './PrizeReceivedModal'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import { mapActions } from 'vuex'

export default {
  name: 'Prizes',
  props: {
    status: {
      required: true
    },
    prizes: {
      required: true
    }
  },
  components: { Prize, VueSlickCarousel, ModalDescription, ModalConfirmation, PrizeReceivedModal },
  data () {
    return {
      currentCarouselCount: 0,
      modalDescription: {
        open: false,
        data: null
      },
      modalConfirmation: {
        open: false,
        data: null
      },
      modalReceived: {
        open: false,
        data: null
      },
      filter: 'new',
      carouselSettings: {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 10,
        slidesToScroll: 1,
        swipeToSlide: true,
        touchThreshold: 100,
        variableWidth: true,
        responsive: [
          {
            breakpoint: 1920,
            settings: {
              slidesToShow: 7
            }
          },
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 6
            }
          },
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 5
            }
          },
          {
            breakpoint: 966,
            settings: {
              arrows: false,
              slidesToShow: 4.5
            }
          },
          {
            breakpoint: 768,
            settings: {
              arrows: true,
              slidesToShow: 4
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows: true,
              slidesToShow: 1
            }
          }
        ]
      }
    }
  },
  computed: {
    getPrizes () {
      return this.prizes
    },
    getUserPrizes () {
      return this.status.prizes
    },
    getStatus () {
      return this.status
    }
  },
  mounted () {
    this.currentCarouselCount = this.getPrizes.length
  },
  methods: {
    ...mapActions({
      getPrize: 'buyGet/getPrize'
    }),
    openDescriptionModal (data) {
      this.modalDescription.open = true
      this.modalDescription.data = data
    },
    closeDescriptionModal () {
      this.modalDescription.open = false
      this.modalDescription.data = null
    },
    openConfirmationModal (data) {
      this.closeDescriptionModal()
      this.modalConfirmation.open = true
      this.modalConfirmation.data = data
    },
    closeConfirmationModal () {
      this.modalConfirmation.open = false
      this.modalConfirmation.data = null
    },
    openPrizeReceivedModal (data) {
      this.closeConfirmationModal()
      this.modalReceived.open = true
      this.modalReceived.data = data
      this.filter = 'old'
    },
    closePrizeReceivedModal () {
      this.modalReceived.open = false
      this.modalReceived.data = null
    },
    collectPrize (data) {
      this.getPrize(data).then((prize) => {
        this.openPrizeReceivedModal({ ...data, prize_code: prize })
      }).catch(() => this.closeConfirmationModal())
    }
  }
}
</script>

<style scoped lang="scss">
.prizes {
  padding-bottom: 15px;
  margin: 15px;
  @media (max-width: 960px) {
    margin: 15px 0;
  }

  .title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 20px 0;
    h4 {
      color: #0277BD;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin: 0;
      order: 1;
      svg {
        margin: 0 5px 0 0;
      }
    }
    .filters {
      display: flex;
      gap: 10px;
      margin-left: 35px;
      order: 2;
      @media (max-width: 480px) {
        width: 100%;
        margin: 5px 0 0 15px;
        order: 3;
      }
      .filter {
        label {
          border-radius: 25px;
          border: 1px solid #ccc;
          color: #999;
          position: relative;
          display: flex;
          align-items: center;
          gap: 5px;
          padding: 7px 15px 7px 35px;
          font-size: 14px;
          transition: all .2s;
          margin: 0;
          cursor: pointer;
          @media (max-width: 480px) {
            font-size: 13px;
          }
          &:before {
            content: '';
            width: 14px;
            height: 14px;
            display: inline-block;
            flex-shrink: 0;
            border: 1px solid #ccc;
            border-radius: 100%;
            position: absolute;
            left: 10px;
          }

          &:after {
            content: '';
            width: 8px;
            height: 8px;
            display: inline-block;
            flex-shrink: 0;
            background: #0277BD;
            border-radius: 100%;
            position: absolute;
            left: 14px;
            transform: scale(0);
            transition: all .2s;
          }
        }
        input {
          display: none;
          &:checked + label {
            border-color: #0277BD;
            color: #0277BD;
            &:before {
              border-color: #0277BD;
            }
            &:after {
              transform: scale(1);
              background: #0277BD;
            }
          }
          &:hover + label {
            &:before {
              border-color: #0277BD;
            }
            &:after {
              transform: scale(1);
              background: #0277BD;
            }
          }
        }
      }
    }
    .close {
      margin-left: auto;
      cursor: pointer;
      padding: 6px 15px;
      background: #fff;
      border: 1px solid #0277BD;
      border-radius: 15px;
      color: #0277BD;
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 13px;
      order: 3;
      @media (min-width: 1024px) {
        margin-left: 30px;
      }
      @media (max-width: 480px) {
        order: 2;
      }

      svg {
        max-width: 16px;
        height: auto;
        margin-left: 0;
        transition: all .2s;
        path {
          transition: all .2s;
          fill: #0277BD;
        }
      }

      &:hover {
        color: #fff;
        background: #0277BD;
        svg {
          transform: rotate(90deg);
          path {
            fill: #fff;
          }
        }
      }
    }
  }

  &-list {
    ::v-deep {
      .slick-list {
        margin: 0 10px;
        @media (max-width: 960px) {
          margin: 0;
        }
      }
      .slick-track {
        display: flex;
        justify-content: flex-start;
        @media (max-width: 480px) {
          justify-content: start;
        }
      }
      .slick-slide {
        margin: 0 10px;
        width: 100%;
        min-width: 200px;
        max-width: 200px;
      }
      .slick-arrow {
        width: 30px;
        height: 30px;
        position: absolute;
        z-index: 1;
        background: #fff;
        border-radius: 100%;

        &:before {
          color: #0277BD;
          font-size: 30px;
        }

        &.slick-prev {
          left: -30px;
          @media (max-width: 768px) {
            left: -15px;
          }
        }

        &.slick-next {
          right: -30px;
          @media (max-width: 768px) {
            right: -15px;
          }
        }
      }
    }
  }
}
</style>
