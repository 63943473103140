<template>
  <transition name="fade-in" v-if="text">
    <div class="message-notice" :class="type ? 'message-notice--' + type : null">{{ $t(text) }}</div>
  </transition>
</template>

<script>
export default {
  name: 'MessageNotice',
  props: {
    type: {
      type: String,
      required: false
    },
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.message-notice {
  margin: 0 0 15px 0;
  display: flex;
  padding: 6px 10px;
  border-radius: 6px;
  color: #e65100;
  background: #fff3e0;
  position: relative;
  font-size: 12px;
  line-height: 18px;
  text-align: center;

  &--message-input-error {
    width: max-content;
    max-width: calc(100% - 20px);
    background: #fce4ec;
    color: #d05759;
    position: absolute;
    bottom: 100%;
    box-sizing: border-box;
    margin: 0 10px 10px 10px;

    @media (max-width: 480px) {
      width: 100%;
    }

    @media (min-width: 480px) {
      left: 50%;
      transform: translateX(-50%);
    }

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #fce4ec;
      position: absolute;
      left: 50%;
      top: 100%;
    }
  }
}
</style>
