<template>
  <div class="typing-animation">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>
</template>

<script>
export default {
  name: 'MessageTipAnimation'
}
</script>

<style scoped lang="scss">
.typing-animation {
  width: 22px;
  justify-content: space-around;
  display: flex;
  padding: 7px 0;
  .dot {
    width: 4px;
    height: 4px;
    border-radius: 100%;
    background-color: $message-tip-bg;
    animation: typing 1.5s infinite ease-in-out;

    &:nth-child(1) {
      animation-delay: .2s;
    }

    &:nth-child(2) {
      animation-delay: .3s;
    }

    &:nth-child(3) {
      animation-delay: .4s;
    }
  }

  @keyframes typing {
    0% {
      transform: translateY(0px);
    }
    28% {
      transform: translateY(-5px);
    }
    44% {
      transform: translateY(0px);
    }
  }
}
</style>
