<template>
  <div class="loader" v-if="getLoading">
    <span class="animation"></span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Loader',
  computed: {
    ...mapGetters({
      getLoading: 'chat/getLoading'
    })
  }
}
</script>

<style scoped lang="scss">
.loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, .1);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  .animation {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: $main-color;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
