<template>
  <div class="chatResponse">
    <transition name="fade-in">
      <div class="messageTip messageTip__him" v-if="question">
        <div class="message">{{ question | urlify }}</div>
      </div>
    </transition>
    <transition name="fade-in">
      <div class="messageTip messageTip__own" v-if="state === 'awaiting'">
        <div class="message message--compact">
          <message-tip-typing />
        </div>
      </div>
      <div class="messageTip messageTip__own" :class="{ welcome: type === 'welcome' }" v-if="answer">
        <div v-if="!isPresenterFeatureEnabled || type !== 'reset-chat'" class="message" v-html="prepareAnswer"></div>
        <div v-else class="message">
          <slot />
        </div>
        <div class="message-options" v-if="options && options.length">
          <button class="message-option" :class="'message-option--'+option.type" v-for="(option, i) in options" :key="'option-'+ i" @click="$emit('select-option', option.id)">
            <span class="icon" v-if="option.icon" v-html="option.icon"></span>
            {{ $t(option.title) }}
          </button>
        </div>
      </div>
    </transition>
    <message-embeds v-if="!type && state === 'finished'" :embeds="embeds" :embeds-status="embedsStatus">
      <template v-slot:products_found>
        <transition name="fade-in">
          <div class="messageTip messageTip__own" :class="{ welcome: type === 'welcome' }">
            <div class="message">
              <div class="posnania-message-with-buttons">
                <p v-html="$t('presenter.reset_chat_message')"></p>
                <button class="message-option message-option--primary" @click="$emit('select-option', 'switchToPresenterMode')" v-if="chatMode === 'default'">
              <span class="icon">
                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg"><g
                  clip-path="url(#clip0_1_2659)"><path
                  d="M1.25 10.5C1.25 10.9148 1.58516 11.25 2 11.25H5.75V7.5H1.25V10.5ZM7.25 11.25H11C11.4148 11.25 11.75 10.9148 11.75 10.5V7.5H7.25V11.25ZM11.75 3.75H10.7633C10.9086 3.46641 11 3.15234 11 2.8125C11 1.67578 10.0742 0.75 8.9375 0.75C7.9625 0.75 7.33203 1.24922 6.52344 2.35078C5.71484 1.24922 5.08437 0.75 4.10938 0.75C2.97266 0.75 2.04688 1.67578 2.04688 2.8125C2.04688 3.15234 2.13594 3.46641 2.28359 3.75H1.25C0.835156 3.75 0.5 4.08516 0.5 4.5V6.375C0.5 6.58125 0.66875 6.75 0.875 6.75H12.125C12.3313 6.75 12.5 6.58125 12.5 6.375V4.5C12.5 4.08516 12.1648 3.75 11.75 3.75ZM4.10703 3.75C3.58906 3.75 3.16953 3.33047 3.16953 2.8125C3.16953 2.29453 3.58906 1.875 4.10703 1.875C4.57344 1.875 4.91797 1.95234 6.125 3.75H4.10703ZM8.9375 3.75H6.91953C8.12422 1.95703 8.45937 1.875 8.9375 1.875C9.45547 1.875 9.875 2.29453 9.875 2.8125C9.875 3.33047 9.45547 3.75 8.9375 3.75Z"
                  fill="currentColor"/></g><defs><clipPath id="clip0_1_2659"><rect width="12" height="12" fill="white"
                                                                                   transform="translate(0.5)"/></clipPath></defs></svg>
              </span>
                  {{ $t('presenter.reset_chat_button') }}
                </button>
                <button class="message-option message-option--secondary" @click="$emit('select-option', 'switchToDefaultMode')" v-if="chatMode === 'presenter'">
                  {{ $t('presenter.ask_mall') }}
                </button>
              </div>
            </div>
            <div class="message-options" v-if="options && options.length">
              <button class="message-option" :class="'message-option--'+option.type" v-for="(option, i) in options" :key="'option-'+ i" @click="$emit('select-option', option.id)">
                <span class="icon" v-if="option.icon" v-html="option.icon"></span>
                {{ $t(option.title) }}
              </button>
            </div>
          </div>
        </transition>
      </template>
      <template v-slot:products_not_found>
        <transition name="fade-in">
          <div class="messageTip messageTip__own" :class="{ welcome: type === 'welcome' }">
          <div class="message">
            <div class="posnania-message-with-buttons">
              <p v-html="$t('presenter.no_products_found')"></p>
              <button class="message-option message-option--primary" @click="$emit('select-option', 'switchToPresenterMode')" v-if="chatMode === 'default'">
              <span class="icon">
                <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg"><g
                  clip-path="url(#clip0_1_2659)"><path
                  d="M1.25 10.5C1.25 10.9148 1.58516 11.25 2 11.25H5.75V7.5H1.25V10.5ZM7.25 11.25H11C11.4148 11.25 11.75 10.9148 11.75 10.5V7.5H7.25V11.25ZM11.75 3.75H10.7633C10.9086 3.46641 11 3.15234 11 2.8125C11 1.67578 10.0742 0.75 8.9375 0.75C7.9625 0.75 7.33203 1.24922 6.52344 2.35078C5.71484 1.24922 5.08437 0.75 4.10938 0.75C2.97266 0.75 2.04688 1.67578 2.04688 2.8125C2.04688 3.15234 2.13594 3.46641 2.28359 3.75H1.25C0.835156 3.75 0.5 4.08516 0.5 4.5V6.375C0.5 6.58125 0.66875 6.75 0.875 6.75H12.125C12.3313 6.75 12.5 6.58125 12.5 6.375V4.5C12.5 4.08516 12.1648 3.75 11.75 3.75ZM4.10703 3.75C3.58906 3.75 3.16953 3.33047 3.16953 2.8125C3.16953 2.29453 3.58906 1.875 4.10703 1.875C4.57344 1.875 4.91797 1.95234 6.125 3.75H4.10703ZM8.9375 3.75H6.91953C8.12422 1.95703 8.45937 1.875 8.9375 1.875C9.45547 1.875 9.875 2.29453 9.875 2.8125C9.875 3.33047 9.45547 3.75 8.9375 3.75Z"
                  fill="currentColor"/></g><defs><clipPath id="clip0_1_2659"><rect width="12" height="12" fill="white"
                                                                                   transform="translate(0.5)"/></clipPath></defs></svg>
              </span>
                {{ $t('presenter.reset_chat_button') }}
              </button>
              <button class="message-option message-option--secondary" @click="$emit('select-option', 'switchToDefaultMode')" v-if="chatMode === 'presenter'">
                {{ $t('presenter.ask_mall') }}
              </button>
            </div>
          </div>
          <div class="message-options" v-if="options && options.length">
            <button class="message-option" :class="'message-option--'+option.type" v-for="(option, i) in options" :key="'option-'+ i" @click="$emit('select-option', option.id)">
              <span class="icon" v-if="option.icon" v-html="option.icon"></span>
              {{ $t(option.title) }}
            </button>
          </div>
        </div>
      </transition>
      </template>
    </message-embeds>
  </div>
</template>

<script>
import { Fancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import urlify from '../../filters/urlify'
import MessageEmbeds from './MessageEmbeds.vue'
import MessageTipTyping from './MessageTipTyping'
import Env from './../../env'
import { mapGetters } from 'vuex'

export default {
  name: 'MessageTip',
  components: { MessageEmbeds, MessageTipTyping },
  props: {
    question: {
      type: String
    },
    answer: {
      type: String
    },
    index: {
      type: Number
    },
    type: {
      type: String
    },
    state: {
      type: String
    },
    link: {
      type: String
    },
    embeds: {
      type: Array,
      default: () => []
    },
    embedsStatus: {
      type: [String, Number],
      default: null
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  filters: {
    urlify
  },
  watch: {
    state (n) {
      if (n === 'finished') {
        setTimeout(() => this.$emit('finished'), 100)
      }
    }
  },
  computed: {
    ...mapGetters({
      chatMode: 'chat/getChatMode'
    }),
    prepareAnswer () {
      const answer = this.$options.filters.urlify(this.answer)
      const images = ['jpg', 'jpeg', 'png', 'webp']

      if (!this.link) {
        return answer
      }

      if (images.some(v => this.link.includes(v)) && this.state === 'finished') {
        return answer + this.prepareImage(this.link)
      }

      const movieID = this.getYTMovieID(this.link)

      if (movieID && this.state === 'finished') {
        return answer + this.prepareYTEmbed(movieID)
      }

      return answer
    },
    isPresenterFeatureEnabled () {
      return Env.ENABLE_PRESENTER_FEATURE
    }
  },
  methods: {
    getYTMovieID (url) {
      const regExp =
        /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/

      const match = url.match(regExp)

      if (match && match[2].length === 11) {
        return match[2]
      }

      return false
    },
    prepareYTEmbed (id) {
      return `<iframe width="480" height="360" allowfullscreen src="https://www.youtube.com/embed/${id}"></iframe>`
    },
    prepareImage (src) {
      return `<img src="${src}" class="img-thumb" data-fancybox alt="${this.answer.slice(0, 50)}" />`
    }
  },
  mounted () {
    Fancybox.bind('[data-fancybox]')
  }
}
</script>

<style lang="scss">
  .message-option {
    display: inline-flex;
    padding: 6px 16px;
    align-items: center;
    gap: 10px;
    border-radius: 25px;
    border: 0;
    color: #E1F5FE;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;

    &--primary {
      background: $message-primary-button-bg;
      color: $message-primary-button-text;

      &:hover {
        background: $message-primary-button-bg-hover;
      }
    }

    &--secondary {
      background: $message-secondary-button-bg;
      color: $message-secondary-button-text;

      &:hover {
        background: $message-secondary-button-bg-hover;
      }
    }

    .icon {
      display: flex;
      align-items: center;
    }

  }
</style>

<style lang="scss" scoped>
.messageTip {
  margin: 0 0 15px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;

  .message {
    padding: 6px 10px;
    max-width: calc(100% - 15px);
    border-radius: 0 6px 6px 6px;
    color: #2C2C2E;
    background: #F2F2F7;
    position: relative;
    font-size: 13px;
    line-height: 18px;
    margin-left: 8.72px;
    white-space: pre-wrap;

    &:before {
      content: '';
      position: absolute;
      width: 8.72px;
      height: 11.12px;
      left: -8px;
      top: 0;
      clip-path: polygon(0 0, 100% 100%, 100% 0);
      background: #F2F2F7;
    }
  }
  a {
    color: #131313;
    text-decoration: none;
    overflow-wrap: break-word;
    border-bottom: 1px solid #131313;
    &:hover {
      border-bottom: 0;
    }
  }

  &::v-deep {
    .country-flag {
      max-width: 18px;
      height: auto;
      margin: 0 0 -2px 0;
      position: relative;
      top: 2px;
    }
    iframe {
      max-width: 100%;
      height: auto;
      margin: 10px 0 0 0;
      display: block;
      border-radius: 6px;
      @media (max-width: 480px) {
        min-height: 200px;
      }
      @media (min-width: 768px) {
        width: 300px;
        min-height: 200px;
      }
      @media (min-width: 1024px) {
        width: 420px;
        min-height: 280px;
      }
    }
    .img-thumb {
      max-width: 220px;
      height: auto;
      margin: 10px 0 0 0;
      display: block;
      cursor: pointer;
      border-radius: 6px;
      @media (min-width: 1024px) {
        max-width: 420px;
      }
    }
  }

  &__own {
    &.welcome {
      margin-top: 10px;
      .message {
        min-width: 230px;
        &--compact {
          min-width: auto;
        }
      }
    }
  }
  &__him {
    flex-direction: row-reverse;

    .message {
      border-radius: 6px 0 6px 6px;
      background: $message-tip-bg;
      color: $message-tip-text;
      margin-right: 8px;

      &:before {
        display: none;
      }

      &:after {
        content: '';
        position: absolute;
        width: 8.72px;
        height: 11.12px;
        right: -8.72px;
        top: 0;
        display: inline-block;
        clip-path: polygon(0 0, 0 100%, 100% 0);
        background: $message-tip-bg;
      }
    }
  }

  .message-options {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-left: 8.72px;
    flex-wrap: wrap;
  }
}

.posnania-message-with-buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
}

.fade-in-enter-active {
  transition: all 0.3s ease;
}

.fade-in-leave-active {
  transition: all 0.3s ease;
}

.fade-in-enter, .fade-in-leave-to {
  opacity: 0;
}
</style>
