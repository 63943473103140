import i18n from '../../i18n/lang'
import Env from './../../env'

const getCountryFlag = (code) => {
  return `<img class="country-flag" src="${require(`@/assets/img/flags/${code}.png`)}" alt="${code}" />`
}

export function parseProductsToEmbeds (products) {
  return products.map((product) => ({
    title: product.name,
    description: product.description,
    picture: product.picture_link,
    price: product.price,
    shop_name: product.shop_name
  }))
}

const state = {
  messages: [],
  socket: null,
  wsStatus: 'finished',
  wsContinue: false,
  isLimitExceeded: false,
  welcomeMessage: {
    question: '',
    answer: '',
    type: 'welcome'
  }
}
const mutations = {
  updateMessages (state, messages) {
    state.messages = messages
  },
  setMessages (state, messages) {
    state.messages.push(messages)
  },
  resetMessages (state) {
    state.messages = []
  },
  setWsStatus (state, status) {
    state.wsStatus = status
  },
  setWsContinue (state, status) {
    state.wsContinue = status
  }
}
const getters = {
  getMessages (state) {
    return state.messages
  },
  getWsStatus (state) {
    return state.wsStatus
  },
  prepareWelcomeMessage (state, getters, rootState) {
    const message = JSON.parse(JSON.stringify(state.welcomeMessage))
    const languages = Env.APP_LANGS
    const userNAme = rootState.auth.userName || localStorage.getItem('aica_username')
    message.answer = languages.map(language => `${i18n.t('chat.welcome', language, { name: userNAme ? ` ${userNAme}` : '' })} ${getCountryFlag(language)}`).join('<br>')

    return message
  },
  preparePresenterWelcomeMessage () {
    return i18n.t('presenter.welcome_question')
  }
}
const actions = {
  createWebSocketConnection ({ state }) {
    const locationId = localStorage.getItem('locationId')
    if (this.socket === undefined) {
      let url = ''
      url = state.wsContinue ? `${Env.CHAT_WEBSOCKET}/api/v1/qa/ws-chat/continue` : `${Env.CHAT_WEBSOCKET}/api/v1/qa/ws-chat/?loc_id=${locationId || 0}`
      this.socket = new WebSocket(url)
    }
    return this.socket
  },
  closeWebSocketConnection () {
    if (!this.socket) {
      return
    }

    this.socket.close()
    this.socket = undefined
  },
  initWebSocket ({ dispatch }) {
    if (!this.socket) {
      dispatch('createWebSocketConnection')
      dispatch('initSocketOnOpen')
      dispatch('initSocketOnMessage')
    }
  },
  sendMessage ({ commit, state, rootState, dispatch }, question) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const uuid = Math.random().toString(16).slice(2)
      const forcedLanguage = rootState.translations.chatLang

      if (!question) {
        return
      }

      commit('setMessages', {
        question,
        answer: '',
        id: uuid,
        state: 'awaiting'
      })

      commit('setWsStatus', 'starting')

      const payload = {}

      if (rootState.chat.chatMode === 'presenter') {
        payload.question = question
        dispatch('chat/setHints', [], { root: true })
      } else {
        payload.question = question
      }

      if (forcedLanguage) {
        payload.forced_language = forcedLanguage.toUpperCase()
      }

      payload.status = rootState.chat.chatMode === 'presenter' ? 'gifter' : 'general'

      if (!this.socket) {
        dispatch('createWebSocketConnection')
        if (rootState.chat.chatMode === 'presenter') {
          dispatch('initSocketOnOpen')
          dispatch('initSocketOnMessage')
        }
        dispatch('initSocketOnOpen', payload)
      } else {
        this.socket.send(JSON.stringify(payload))
      }

      dispatch('initSocketOnMessage', uuid)
    })
  },
  sendHint ({ commit, dispatch, rootState }, hint) {
    return new Promise((resolve, reject) => {
      const uuid = Math.random().toString(16).slice(2)
      const forcedLanguage = rootState.translations.chatLang

      commit('setMessages', {
        question: hint,
        answer: '',
        id: uuid,
        state: 'awaiting'
      })

      commit('setWsStatus', 'starting')
      dispatch('chat/setHints', [], { root: true })

      const payload = {
        question: hint
      }

      payload.status = 'gifter'

      if (forcedLanguage) {
        payload.forced_language = forcedLanguage.toUpperCase()
      }

      if (!this.socket) {
        dispatch('createWebSocketConnection')
        dispatch('initSocketOnOpen', payload)
      } else {
        this.socket.send(JSON.stringify(payload))
      }

      dispatch('initSocketOnMessage', uuid)
    })
  },
  initSocketOnOpen ({ rootState }, payload) {
    const token = localStorage.getItem('accessToken')
    let language = rootState.translations.chatLang
    this.socket.onopen = () => {
      if (Env.ENABLE_PRESENTER_FEATURE && rootState.chat.chatMode === 'presenter') {
        language = language === 'ue' ? 'uk' : language
        this.socket.send(JSON.stringify({
          token,
          language
        }))
      } else {
        this.socket.send(JSON.stringify({ token }))
      }

      if (payload) {
        this.socket.send(JSON.stringify(payload))
      }
    }
  },
  catchChatModeOnSocketMessage ({ commit, rootState, dispatch }, uuid) {
    this.socket.onmessage = (e) => {
      const response = JSON.parse(e.data)
      const products = response.products
      commit('chat/setChatMode', (response.gifter_status === 'gifter' ? 'presenter' : 'default') || rootState.chat.chatMode, { root: true })
      if (rootState.chat.chatMode === 'default') {
        dispatch('initSocketOnMessageChatDefault', { uuid: uuid, response: response })
      }
      if (rootState.chat.chatMode === 'presenter' || products) {
        dispatch('initSocketOnMessageChatPresenter', { uuid: uuid, response: response })
      }
    }
  },
  initSocketOnMessage ({ rootState, dispatch }, uuid) {
    dispatch('catchChatModeOnSocketMessage', uuid)
  },
  initSocketOnMessageChatDefault ({ state, commit }, data) {
    const messages = JSON.parse(JSON.stringify(state.messages))
    const messageState = String(data.response.state).toLowerCase()

    if (data.uuid) {
      const findResponse = messages.findIndex(response => response.id === data.uuid)
      messages[findResponse].answer += data.response.piece ? data.response.piece : ''
      messages[findResponse].state = messageState

      if (data.response.link) {
        messages[findResponse].link = data.response.link
      }
    }

    commit('setWsStatus', messageState)
    commit('updateMessages', messages)
  },
  initSocketOnMessageChatPresenter ({ state, commit, dispatch }, data) {
    let messages = JSON.parse(JSON.stringify(state.messages))
    const messageState = String(data.response.state).toLowerCase()

    if (data.uuid) {
      const findResponse = messages.findIndex(response => response.id === data.uuid)

      messages[findResponse].answer += data.response.piece ? data.response.piece : ''
      messages[findResponse].state = messageState
      if (messageState === 'finished') {
        dispatch('chat/setHints', data.response.hints, { root: true })
      }

      if (data.response.products) {
        messages[findResponse].embeds = parseProductsToEmbeds(data.response.products)
        dispatch('chat/setHints', [], { root: true })
      }
      messages[findResponse].embeds_status = data.response.products ? data.response.products.length : null
    } else {
      if (messageState === 'finished') {
        dispatch('closeWebSocketConnection')
        return
      }

      messages = [
        {
          id: Math.random().toString(16).slice(2),
          question: null,
          answer: '',
          state: messageState
        }]

      if (data.response.hints) {
        dispatch('chat/setHints', data.response.hints, { root: true })
      }
    }

    commit('setWsStatus', messageState)
    commit('updateMessages', messages)
  },
  welcomeMessage ({ commit, state }) {
    if (state.messages.length) {
      return
    }
    setTimeout(() => {
      commit('updateMessages', state.welcomeMessage)
    }, 600)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
