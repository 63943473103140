import { render, staticRenderFns } from "./MessageEmbed.vue?vue&type=template&id=f8758594&scoped=true&"
import script from "./MessageEmbed.vue?vue&type=script&lang=js&"
export * from "./MessageEmbed.vue?vue&type=script&lang=js&"
import style0 from "./MessageEmbed.vue?vue&type=style&index=0&id=f8758594&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8758594",
  null
  
)

export default component.exports