import Vue from 'vue'
import Widget from './components/Widget'
import store from './store'
import i18n from './i18n/lang'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'simplebar'
import 'simplebar/dist/simplebar.css'
import { VueRecaptcha } from 'vue-recaptcha'
import ResizeObserver from 'resize-observer-polyfill'
import VueGtag from 'vue-gtag'
import * as Env from './env'

window.AllowSendMessage = false
window.ResizeObserver = ResizeObserver
Vue.config.productionTip = false

Vue.use(VueAxios, axios)

Vue.use(VueToast, {
  position: 'top',
  duration: 5000
})
Vue.$toast.clear()

Vue.component('vue-recaptcha', VueRecaptcha)

const googleGTag = Env.GOOGLE_GTAG

if (googleGTag) {
  Vue.use(VueGtag, {
    config: {
      id: googleGTag
    }
  })
}

new Vue({
  i18n,
  store,
  render: h => h(Widget)
}).$mount('#app')
