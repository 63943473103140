<template>
  <transition name="fade">
    <div class="product-dialog">
      <div class="wrapper">
        <div class="header-modal">
          <div class="picture">
            <img :src="data.image_url" :alt="data.title" />
          </div>
        </div>
        <div class="content-modal">
          <h3>{{ $t('buy-get.are_you_sure_claim_prize') }} <strong>{{ data.title[$root.$i18n.locale.toUpperCase()] }}</strong></h3>

          <div class="buttons">
            <button class="cancel" @click="$emit('close')">
              {{ $t('buy-get.cancel') }}
            </button>
            <button class="collect" @click="$emit('collect', data)">
              <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1_2659)">
                  <path d="M1.25 10.5C1.25 10.9148 1.58516 11.25 2 11.25H5.75V7.5H1.25V10.5ZM7.25 11.25H11C11.4148 11.25 11.75 10.9148 11.75 10.5V7.5H7.25V11.25ZM11.75 3.75H10.7633C10.9086 3.46641 11 3.15234 11 2.8125C11 1.67578 10.0742 0.75 8.9375 0.75C7.9625 0.75 7.33203 1.24922 6.52344 2.35078C5.71484 1.24922 5.08437 0.75 4.10938 0.75C2.97266 0.75 2.04688 1.67578 2.04688 2.8125C2.04688 3.15234 2.13594 3.46641 2.28359 3.75H1.25C0.835156 3.75 0.5 4.08516 0.5 4.5V6.375C0.5 6.58125 0.66875 6.75 0.875 6.75H12.125C12.3313 6.75 12.5 6.58125 12.5 6.375V4.5C12.5 4.08516 12.1648 3.75 11.75 3.75ZM4.10703 3.75C3.58906 3.75 3.16953 3.33047 3.16953 2.8125C3.16953 2.29453 3.58906 1.875 4.10703 1.875C4.57344 1.875 4.91797 1.95234 6.125 3.75H4.10703ZM8.9375 3.75H6.91953C8.12422 1.95703 8.45937 1.875 8.9375 1.875C9.45547 1.875 9.875 2.29453 9.875 2.8125C9.875 3.33047 9.45547 3.75 8.9375 3.75Z" fill="#0277BD"/>
                </g>
                <defs>
                  <clipPath id="clip0_1_2659">
                    <rect width="12" height="12" fill="white" transform="translate(0.5)"/>
                  </clipPath>
                </defs>
              </svg>
              {{ $t('buy-get.confirm_claim_prize') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ModalConfirmation',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.product-dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, .1);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;

  .wrapper {
    background: #fff;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    max-width: 450px;
    margin: 0 10px;
    width: 100%;
    position: relative;
    overflow: auto;
    max-height: 90vh;

    .header-modal {
      .picture {
        height: 225px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .close {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        padding: 10px 20px;
        height: 36px;
        background: #0277BD;
        border: 0;
        border-radius: 15px;
        color: #fff;
        display: flex;
        align-items: center;
        gap: 10px;
        svg {
          path {
            fill: #555;
          }
        }

        &:hover {
          svg {
            path {
              fill: #111;
            }
          }
        }
      }
    }

    .content-modal {
      padding: 25px;
      @media (max-width: 996px) {
        padding: 15px;
      }

      h3 {
        font-size: 16px;
        font-weight: 500;
        margin-top: 0;
        color: #757575;
        text-align: center;
        margin-bottom: 10px;
        strong {
          margin-top: 5px;
          display: block;
          color: #01579B;
          font-weight: 500;
        }
      }

      .text-intro {
        margin: 0;
        color: #555;
        font-size: 14px;
        &::v-deep {
          a {
            color: #333;
            text-decoration: underline;
            &:hover {
              color: #131313;
              text-decoration: none;
            }
          }
        }
      }

    .buttons {
      display: flex;
      align-items: baseline;
      gap: 15px;
    }

      button {
        display: flex;
        padding: 8px 24px;
        width: 100%;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 15px;
        background: #F5F5F5;
        border: none;
        font-size: 13px;
        color: #757575;
        cursor: pointer;
        transition: all .2s;
        &:hover {
          background: #f1f1f1;
        }

        &.collect {
          background: #E1F5FE;
          color: #0277BD;
          font-weight: 600;
          animation: pulse-animation-2 2s infinite;
          margin-top: 25px;
          white-space: nowrap;
          &:hover {
            background: #0277BD;
            color: #E1F5FE;
            svg {
              path {
                fill: #E1F5FE;
              }
            }
          }
        }
      }
    }

    .footer-modal {
      position: sticky;
      bottom: 0;
      background: #fff;
      padding: 10px 25px;
      border-top: 1px solid #eee;
      @media (max-width: 996px) {
        border-top: 1px solid #eee;
        padding: 10px 15px;
      }
      @media (max-width: 480px) {
        margin-top: auto
      }
    }
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
