import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Env from './../env'
const lodash = require('lodash')

Vue.use(VueI18n)

const fallbackLocale = 'en'

const loadLocaleMessages = () => {
  const locales = require.context('@/i18n/lang', true, /.*\.json$/)
  let messages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })

  if (Env.APP_I18N_FILE) {
    const extendMessages = require(`@/../config/i18n/${Env.APP_I18N_FILE}`)

    if (extendMessages) {
      messages = lodash.merge(messages, extendMessages)
    }
  }

  return messages
}

const browserLanguage = () => {
  const userLocale =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language

  if (userLocale.includes('-')) {
    const locale = userLocale.split('-')
    return locale[0].toLowerCase()
  }

  const languages = Env.APP_LANGS

  if (!languages.includes(userLocale)) {
    return 'en'
  }

  return userLocale
}

const i18n = new VueI18n({
  locale: Env.APP_LANG_FORCED ? Env.APP_LANG_FORCED : (localStorage.getItem('aica_i_lang') || browserLanguage()) || fallbackLocale,
  fallbackLocale: fallbackLocale,
  messages: loadLocaleMessages()
})

export default i18n
