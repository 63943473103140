<template>
    <div class="chat-info">
        <div class="content" :class="{ collapsed: collapsed }">
            <div class="collapse-row">
                <slot />

                <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" class="collapse" @click="toggleCollapse()" v-if="!singular">
                    <path class="heroicon-ui" d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"></path>
                </svg>
            </div>

            <template v-if="collapsed && expanded">
                <slot name="collapsed" />
            </template>
        </div>
    </div>
  </template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'ChatInfo',
  props: {
    singular: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      collapsed: false
    }
  },
  created () {
    window.addEventListener('resize', this.responsiveCollapse)
  },
  mounted () {
    this.responsiveCollapse()
  },
  methods: {
    ...mapActions({
      calculateInsideHeight: 'chat/calculateInsideHeight'
    }),
    responsiveCollapse () {
      this.collapsed = window.innerWidth >= 996
    },
    toggleCollapse () {
      this.collapsed = !this.collapsed
      this.calculateInsideHeight()
    }
  }
}
</script>

<style scoped lang="scss">
  .chat-info {
    position: sticky;
    top: 0;
    z-index: 1;
    margin: 5px 15px 15px 15px;
    background: #fff;
    padding-top: 10px;

    .content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;

        border-radius: 6px;
        background: $chat-info-bg;
        padding: 10px 20px;

        font-size: 13px;
        color: $chat-info-text;
        position: sticky;

        top: 5px;
        left: 0;
        right: 0;
        z-index: 1;
        @media (max-width: 996px) {
            column-gap: 10px;
            justify-content: center;
        }

        @media (max-width: 996px) and (orientation: landscape) {
            flex-direction: row;
            justify-content: center;
            column-gap: 10px;
            padding: 10px 0;
        }
        @media (max-width: 480px) {
            flex-direction: row;
            justify-content: center;
            column-gap: 10px;
            padding: 10px 0;
        }

        .collapse-row {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: 10px;
            padding: 0 15px;
            @media (min-width: 996px) {
                svg.collapse {
                    display: none;
                }
            }

            @media (max-width: 996px) {
                width: 100%;
            }

            svg.collapse {
                margin-left: auto;
                position: absolute;
                right: 10px;
                transition: all .2s;
                cursor: pointer;
            }
        }

        &:not(.collapsed) {
            // animation: pulse-animation 4s infinite;
        }

        &.collapsed {
            .collapse-row {
                svg.collapse {
                    transform: rotate(180deg);
                }
            }
        }

        p {
            font-weight: 500;
            @media (max-width: 480px) {
              text-align: center;
              line-height: 20px;
            }
            &.text--primary {
                color: $message-primary-text;
            }

            &.text--secondary {
                color: $message-primary-text;
            }
        }

        button {
            display: inline-flex;
            padding: 6px 16px;
            align-items: center;
            gap: 10px;
            border-radius: 25px;
            border: 0;
            color: #E1F5FE;
            font-size: 13px;
            font-weight: 500;
            line-height: 20px;
            cursor: pointer;
            font-family: 'Roboto', sans-serif;

            .icon {
                display: flex;
                align-items: center;
            }

            &.button--primary {
                background: $message-primary-button-bg;
                color: $message-primary-button-text;
                font-weight: 500;

                &:hover {
                    background: $message-primary-button-bg-hover;
                }
                &:disabled {
                  opacity: .7;
                  cursor: default;
                }
            }

            &.button--secondary {
                background: $message-secondary-button-bg;
                color: $message-secondary-button-text;
                font-weight: 500;

                &:hover {
                    background: $message-secondary-button-bg-hover;
                }
                &:disabled {
                  opacity: .7;
                  cursor: default;
                }
            }

            &.pulse {
                box-shadow: 0 0 1px 1px #0277BD;
                animation: pulse-animation-1 2s infinite;
                //@media (min-width: 680px) and (max-width: 996px) {
                //  order: 2;
                //}
                //@media (max-width: 480px) {
                //  margin-bottom: 15px;
                //}
            }

            &.outline {
                background: none;
                color: #0277BD;
                //@media (min-width: 680px) and (max-width: 996px) {
                //  order: 10;
                //}
                svg {
                    path {
                        fill: #0277BD;
                    }
                }
            }
        }

        @keyframes pulse-animation {
            0% {
                box-shadow: 0 0 0 0 rgba(2, 119, 189, 0.4);
            }
            25% {
                box-shadow: 0 0 0 10px rgba(2, 119, 189, 0);
            }
        }
    }

  }
  </style>
