import http from '../../services/http'
import Vue from 'vue'
import i18n from '../../i18n/lang'

const state = {
  accessToken: null,
  userInfo: null,
  error: null,
  authMethods: {},
  userName: null
}
const mutations = {
  setAccessToken (state, token) {
    state.accessToken = token
  },
  setUserInfo (state, user) {
    state.userInfo = user
  },
  setAvailableProviders (state, providers) {
    state.availableProviders = providers
  },
  setAuthMethods (state, methods) {
    state.authMethods = methods
  },
  setUserName (state, name) {
    state.userName = name
    localStorage.setItem('aica_username', name)
  },
  resetAuth (state) {
    state.accessToken = null
    state.userName = null
    state.userInfo = null
    localStorage.removeItem('aica_username')
    localStorage.removeItem('accessToken')
  }
}
const getters = {
  getAccessToken (state) {
    return state.accessToken
  },
  getPhoneNumber (state) {
    return state.phoneNumber
  },
  getAuthState (state) {
    return state.authMethods
  },
  getUserName (state) {
    return state.userName
  }
}
const actions = {
  authRegisterWithPhone ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('chat/setLoading', true, { root: true })
      http
        .post('/auth/login/phone-number/', {
          phone_number: payload
        })
        .then((r) => {
          commit('chat/setLoading', false, { root: true })
          Vue.$toast.success(i18n.t('auth.notices.pin_sent'))
          resolve(r.data)
        })
        .catch((e) => {
          const responseCode = e.response.status
          if (responseCode === 400) {
            Vue.$toast.error(i18n.t('auth.notices.invalid_phone'))
          } else if (responseCode === 425) {
            Vue.$toast.error(i18n.t('auth.notices.wait_5m'))
          } else if (responseCode === 429) {
            Vue.$toast.error(i18n.t('auth.notices.wait_5m'))
          } else {
            Vue.$toast.error(i18n.t('auth.notices.unknown_error'))
          }

          commit('chat/setLoading', false, { root: true })
          reject(e)
        })
    })
  },
  authRegisterWithCaptcha ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('chat/setLoading', true, { root: true })
      http
        .post('/auth/login/recaptcha/', {
          user_name: payload.user_name,
          google_recaptcha_response: payload.google_recaptcha_response
        })
        .then((r) => {
          commit('chat/setLoading', false, { root: true })
          localStorage.setItem('accessToken', r.data.access_token)
          commit('setAccessToken', r.data.access_token)
          commit('setUserName', payload.user_name)
          commit('chat/setAuthState', 'loggedIn', { root: true })
          resolve(r.data)
        }).catch((e) => {
          commit('chat/setLoading', false, { root: true })
          reject(e)
        })
    })
  },
  authReset ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('chat/setLoading', true, { root: true })
      http
        .post('/auth/remind/pin-code/', {
          phone_number: payload
        })
        .then((r) => {
          commit('chat/setLoading', false, { root: true })
          Vue.$toast.success(i18n.t('auth.notices.pin_sent'))
          resolve(r.data)
        })
        .catch((e) => {
          const responseCode = e.response.status
          if (responseCode === 400) {
            Vue.$toast.error(i18n.t('auth.notices.invalid_phone'))
          } else if (responseCode === 425) {
            Vue.$toast.error(i18n.t('auth.notices.wait_5m'))
          } else if (responseCode === 429) {
            Vue.$toast.error(i18n.t('auth.notices.wait_5m'))
          } else {
            Vue.$toast.error(i18n.t('auth.notices.unknown_error'))
          }
          commit('chat/setLoading', false, { root: true })
          reject(e)
        })
    })
  },
  authLogin ({ commit, dispatch }, payload) {
    if (payload.accessToken) {
      localStorage.setItem('accessToken', payload.accessToken)
      commit('setAccessToken', payload.accessToken)
      commit('chat/setAuthState', 'loggedIn', { root: true })
      commit('chat/setLoading', false, { root: true })
      return
    }

    return new Promise((resolve, reject) => {
      commit('chat/setLoading', true, { root: true })
      http.post('/auth/login/pin-code/', payload)
        .then(r => {
          localStorage.setItem('accessToken', r.data.access_token)
          commit('setAccessToken', r.data.access_token)
          commit('chat/setAuthState', 'loggedIn', { root: true })
          // dispatch('messages/welcomeMessage', null, { root: true })
          commit('chat/setLoading', false, { root: true })
          resolve(r.data)
        })
        .catch((e) => {
          const responseCode = e.response.status
          Vue.$toast.error(responseCode === 400 ? i18n.t('auth.notices.pin_invalid') : i18n.t('auth.notices.unknown_error'))
          commit('chat/setLoading', false, { root: true })
          reject(e)
        })
    })
  },
  setAuthMethods ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('chat/setLoading', true, { root: true })
      http.get('/auth/login/enabled_methods')
        .then(r => {
          commit('setAuthMethods', r.data)
          commit('chat/setLoading', false, { root: true })
          resolve(r.data)
        })
        .catch((e) => {
          commit('chat/setLoading', false, { root: true })
          reject(e)
        })
    })
  },
  checkOauth ({ commit }, payload) {
    if (payload) {
      return new Promise((resolve, reject) => {
        if (payload) {
          localStorage.setItem('accessToken', payload)
          commit('setAccessToken', payload)
          commit('chat/setAuthState', 'loggedIn', { root: true })
          commit('chat/setLoading', false, { root: true })
          window.history.replaceState({}, document.title, '/')
          resolve(payload)
        }
      })
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
